import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Mapbox from '../components/mapbox';

export default function ContactPage() {
    return (
        <Layout>
            <Seo
                title="Contact"
                description="Contact Throughlife Financial Solutions"
            />
            <div className="intbanner">
                <StaticImage
                    src="../images/tl-int-banner3.jpg"
                    alt="Contact"
                    placeholder="dominant_color"
                    layout="fullWidth"
                    quality={100}
                />
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-5 col-bleed contact--content">
                            <h1>
                                Contact
                                <span>
                                    Throughlife Financial Solutions Pty Ltd
                                </span>
                            </h1>
                            <h2>Specific Contact:</h2>
                            <p>
                                Queries and Requests:{' '}
                                <a href="mailto:admin@throughlife.com.au">
                                    admin@throughlife.com.au
                                </a>
                            </p>
                            <h2>Contact Information:</h2>
                            <p>
                                Phone - (08) 9375 1149
                                <br />
                                Fax - (08) 9276 9610
                            </p>
                            <h2>Postal Address</h2>
                            <p>
                                PO Box 702, Morley,
                                <br />
                                Perth, WA 6943
                            </p>
                            <h2>Office Address</h2>
                            <p>
                                Suite 9, Level 2, 3 Bookham Street, Morley,
                                <br />
                                Perth WA 6062
                            </p>
                            <p>
                                Designated signed parking is available at the
                                rear of the building during meeting times.
                            </p>
                        </div>
                        <div className="col-lg-7 col-bleed map">
                            <Mapbox />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
